import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { Egate } from "./Landing_PAGE/Egate";
const Interviewtestform = React.lazy(() => import("./eGRADTestinterface/Interviewtestform.jsx"));
const Egradtest = React.lazy(() => import("./eGRADTestinterface/Egradtest.jsx"));
const IItjamMockTest1 = React.lazy(() => import("./PGMockTest/IITJAM/IItjamMockTest1.jsx"));
const GATEMockTest1 = React.lazy(() => import("./PGMockTest/GATE/GATEMockTest1.jsx"));
const BITSATMocktest_1 = React.lazy(() => import("./MockTest/BITSAT/BITSATMocktest_1.jsx"));
const JeeMainsMocktest = React.lazy(() => import("./MockTest/JEE(MAINS)/JeeMainsMocktest.jsx"));
const NEETMockTest = React.lazy(() => import("./MockTest/NEET/NEETMockTest.jsx"));
const JeeAdvNew = React.lazy(() => import("./MockTest/JEE(ADVANCE)/JeeAdvNew.jsx"));
const ScientificCalculator = React.lazy(() => import("./PGMockTest/GATE/ScientificCalculator.jsx"));

const IsroMocktest1 = React.lazy(() => import("./PGMockTest/ISRO/IsroMocktest1.jsx"));
const EseMocktest1 = React.lazy(() => import("./PGMockTest/ESE/EseMocktest1.jsx"));
const CivilEngineeringGeneralInstructions = React.lazy(() => import('./PGMockTest/ESE/Subjects/CivilEngineering(CE)/CivilEngineeringGeneralInstructions.jsx'));
const GateMTGeneralInstructions = React.lazy(() => import("./PGMockTest/GATE/Subjects/GateMTSubject/GateMTGeneralInstructions.jsx"));
const GateMEGeneralInstructions = React.lazy(() => import("./PGMockTest/GATE/Subjects/GateMESubject/GateMEGeneralInstructions.jsx"));
const GateXEGeneralInstructions = React.lazy(() => import("./PGMockTest/GATE/Subjects/GATEXESubject/GateXEGeneralInstructions.jsx"));
const IItjamMAthsGeneralInstructions = React.lazy(() => import("./PGMockTest/IITJAM/Subjects/Mathematics/IItjamMAthsGeneralInstructions.jsx"));
const IItjamPhysicsGeneralInstructions = React.lazy(() => import("./PGMockTest/IITJAM/Subjects/Physics/IItjamPhysicsGeneralInstructions.jsx"));
const Genralinstructions = React.lazy(() => import("./MockTest/BITSAT/Genralinstructions.jsx"));
const NEETGenralinstructions = React.lazy(() => import("./MockTest/NEET/NEETGenralinstructions.jsx"));
const GeneralStudiesGeneralInstructions = React.lazy(() => import('./PGMockTest/ESE/Subjects/GeneralStudies/GeneralStudiesGeneralInstructions.jsx'));
const MechanicalEngineeringGeneralInstructions = React.lazy(() => import("./PGMockTest/ESE/Subjects/MechanicalEngineering(ME)/MechanicalEngineeringGeneralInstructions.jsx"));
const JeeAdvanceGeneralInstructions = React.lazy(() => import("./MockTest/JEE(ADVANCE)/JeeAdvanceGeneralInstructions.jsx"));
const JeeMainsGeneralInstructions = React.lazy(() => import("./MockTest/JEE(MAINS)/JeeMainsGeneralInstructions.jsx"));
const GatePIGeneralInstructions = React.lazy(() => import("./PGMockTest/GATE/Subjects/GatePhysicsSubject/GatePIGeneralInstuctions.jsx"));
const PgInstructions = React.lazy(() => import('./PGMockTest/PgInstructions.jsx'))
const Uginstuctions = React.lazy(() => import('./MockTest/Uginstruction.jsx'))
const CovertJson = React.lazy(() => import('./MockTest/BITSAT/CovertJson.jsx'))
const StudentFormandMailGate = React.lazy(() => import('./PGMockTest/GATE/StudentFormandMailGate.jsx'))
const NewMockTestformFileTopicwise = React.lazy(() => import('./MockTest/NewMockTestformFileTopicwise.jsx'))
const GateCEGeneralInstructions = React.lazy(() => import('./PGMockTest/GATE/Subjects/GateCESubject/GateCEGeneralInstructions.jsx'))
const IsroEceGeneralInstructions = React.lazy(() => import('./PGMockTest/ISRO/Subjects/ECE_SUBJECT/IsroEceGeneralInstructions.jsx'))
const UgExamHomePage = React.lazy(() => import("./ExamHomePage/UgExamHomePage.jsx"))
const OnlineRecordedLecturesHomePage = React.lazy(() => import("./ExamHomePage/OnlineRecordedLecturesHomePages/OnlineRecordedLecturesHomePage.jsx"))
const OnlineVideoLecturesHomePage = React.lazy(() => import("./OnlineVideoLecturesHomePage/OnlineVideoLecturesHomePage.jsx"))
const OnlineTestSerieshome = React.lazy(() => import('./OnlineTestSeriesHomePages/OnlineTestSerieshome.jsx'))
const UG_HOME = React.lazy(() => import('./components/ug_homepage/UG_HOME'));
const Download = React.lazy(() => import('./components/ug_homepage/components/Downloads/Download.jsx'));
const IitjeeDownloadPage = React.lazy(() => import('./components/ug_homepage/components/Downloads/IitjeeDownloadPage.jsx'));
const Pg_home_page = React.lazy(() => import('./components/PG/PG_homePage/Pg_home_page').then(module => ({ default: module.Pg_home_page })));
const State = React.lazy(() => import('./components/PG/context/State.jsx').then(module => ({ default: module.State })));
const ScrollToTop = React.lazy(() => import('./Landing_PAGE/ScrollToTop.js'));
const PG_DOWNLOAD = React.lazy(() => import('./components/PG/PG DOWNLOADS/PG_DOWNLOAD.jsx'));
const GateDownloadPage = React.lazy(() => import('./components/PG/PG DOWNLOADS/GateDownloadPage.jsx'));
const Pricing_Policy = React.lazy(() => import('./components/UG/Pricing_Policy.jsx'));
const Contact = React.lazy(() => import('./components/ug_homepage/components/Contact/Contact.js'));
const About_us = React.lazy(() => import('./components/ug_homepage/components/About_section/About_us.jsx'));
const TERMsCONDITIONS = React.lazy(() => import('./components/UG/TERMsCONDITIONS').then(module => ({ default: module.TERMsCONDITIONS })));
const Privacypolicy = React.lazy(() => import('./components/UG/Privacypolicy').then(module => ({ default: module.Privacypolicy })));
const CancellationRefundPolicy = React.lazy(() => import('./components/UG/CancellationRefundPolicy.jsx'));
const JeeVideoLectures = React.lazy(() => import('./VideoLectures/JeeVideoLectures.jsx'))
const JeeMathsTopics = React.lazy(() => import('./VideoLectures/JeeMathsTopics.jsx'))

const OurOfferingsPage = React.lazy(() => import("./Landing_PAGE/OurOfferingsPage.jsx"))
const Careers_file = React.lazy(() => import("./Landing_PAGE/Careers_file.jsx"))


const App = () => {
  const LoadingSpinner = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div className="spinner" style={{ border: '8px solid #f3f3f3', borderTop: '8px solid #3498db', borderRadius: '50%', width: '50px', height: '50px', animation: 'spin 2s linear infinite' }}></div>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
  
  return (
    <State>
      <Router>
        <ScrollToTop />
        <Routes>
          {/* This is a link for landing page */}
          <Route path="/" element={<Egate />} />
          <Route path = "/OurOfferingsPage" element={<Suspense fallback={<div>Loading ...</div>}><OurOfferingsPage /></Suspense>} />
          <Route path = "/Careers_file" element={<Suspense fallback={<div>Loading ...</div>}><Careers_file /></Suspense>} />
          

          {/* ********************* UG EXAM HOME PAGES ************************************* */}
          <Route path="/UGHome" element={ <Suspense fallback={<div>Loading UG Home page...</div>}><UG_HOME /></Suspense>} />
          <Route path="/UgExamHomePage/:examId" element={<Suspense fallback={<div>Loading ...</div>}><UgExamHomePage /></Suspense>} />
          <Route path="/OnlineRecordedLecturesHomePage/:examId" element={<Suspense fallback={<div>Loading ...</div>}><OnlineRecordedLecturesHomePage /></Suspense>} />

          <Route path="/OnlineTestSerieshome/:examId" element={<Suspense fallback={<div>Loading...</div>}><OnlineTestSerieshome /></Suspense>} />
          <Route path="/OnlineVideoLecturesHomePage/:examId" element={<Suspense fallback={<div>Loading...</div>}><OnlineVideoLecturesHomePage /></Suspense>} />
          <Route path="/PGHome" element={ <Suspense fallback={<div>Loading ...</div>}><Pg_home_page /></Suspense> } />
          {/* *********************************FOOTER FILES********************* */}
          <Route path="/contactUs" element={<Suspense fallback={<div>Loading...</div>}><Contact /></Suspense>} />
          <Route path="/About_us" element={<Suspense fallback={<div>Loading...</div>}><About_us /></Suspense>} />
          <Route path="/terms" element={ <Suspense fallback={<div>Loading...</div>}><TERMsCONDITIONS /></Suspense> } />
          <Route path="/CancellationRefundPolicy" element={<Suspense fallback={<div>Loading...</div>}><CancellationRefundPolicy /></Suspense>}/>
          <Route path="/policy" element={<Suspense fallback={<div>Loading...</div>}> <Privacypolicy /></Suspense> } />
          <Route path="/pricingpolicy" element={ <Suspense fallback={<div>Loading...</div>}><Pricing_Policy /> </Suspense>} />
          {/* *****************************FOOTER FILES END*************************  */}
          <Route path="/CovertJson" element={<Suspense fallback={<div>Loading...</div>}><CovertJson /></Suspense>} />
          <Route path="/Uginstructions/:examId/:mockTestId/:mockTestTypeId" element={<Suspense fallback={<div>Loading ...</div>}><Uginstuctions /></Suspense>} />
          <Route path="/PGinstructions/:selectedSubjectId/:selectedMockTestId" element={<Suspense fallback={<div>Loading ...</div>}><PgInstructions /></Suspense>} />
          <Route path="/download" element={<Suspense fallback={<div>Loading...</div>}><Download /></Suspense>} />
          <Route path="/IitjeeDownloadPage/:id" element={<Suspense fallback={<div>Loading...</div>}><IitjeeDownloadPage /></Suspense>} />
          <Route path="/PgMockTest" element={<Suspense fallback={<div>Loading...</div>}><StudentFormandMailGate /></Suspense>} />
          <Route path="/UGMockTest" element={<Suspense fallback={<div>Loading...</div>}><NewMockTestformFileTopicwise /></Suspense>} />
          <Route path="/IItjamMockTest1/:selectedSubjectId/:selectedMockTestId" element={<Suspense fallback={<LoadingSpinner />}><IItjamMockTest1 /></Suspense>}/>
          <Route path="/GATEMockTest1/:selectedSubjectId/:selectedMockTestId" element={<Suspense fallback={<LoadingSpinner />}><GATEMockTest1 /></Suspense>}/>
          <Route path="/Gate_ME_General_Instructions/:selectedSubjectId/:selectedMockTestId" element={<Suspense fallback={<div>Loading...</div>}><GateMEGeneralInstructions /></Suspense>} />
          <Route path="/Gate_XE_General_Instructions/:selectedSubjectId/:selectedMockTestId"element={<Suspense fallback={<div>Loading...</div>}><GateXEGeneralInstructions /></Suspense>}/>
          <Route path="/Gate_PI_General_Instructions/:selectedSubjectId/:selectedMockTestId"element={<Suspense fallback={<div>Loading...</div>}><GatePIGeneralInstructions /></Suspense>} />
          <Route path="/Gate_MT_General_Instructions/:selectedSubjectId/:selectedMockTestId" element={<Suspense fallback={<div>Loading...</div>}><GateMTGeneralInstructions /></Suspense>}/>
          <Route path="/IITJAM_MATHS_General_Instructions/:selectedSubjectId/:selectedMockTestId"  element={<Suspense fallback={<div>Loading...</div>}><IItjamMAthsGeneralInstructions /></Suspense>}/>
          <Route path="/IITJAM_PHYSICS_General_Instructions/:selectedSubjectId/:selectedMockTestId" element={<Suspense fallback={<div>Loading...</div>}><IItjamPhysicsGeneralInstructions /></Suspense>} />
          <Route path="/Genralinstructions/:examId/:mockTestId/:mockTestTypeId" element={<Suspense fallback={<div>Loading...</div>}><Genralinstructions /></Suspense>}/>
          <Route path="/BITSATMocktest_1/:examId/:mockTestId/:mockTestTypeId" element={<Suspense fallback={<LoadingSpinner />}><BITSATMocktest_1 /></Suspense>} />
          <Route path="/JeeMainsGeneralInstructions/:examId/:mockTestId/:mockTestTypeId" element={<Suspense fallback={<div>Loading...</div>}><JeeMainsGeneralInstructions /></Suspense>}/>
          <Route path="/Jee_mains_1/:examId/:mockTestId/:mockTestTypeId" element={<Suspense fallback={<LoadingSpinner />}><JeeMainsMocktest /></Suspense>}/>
          <Route path="/JEE_Advance_general_instructions/:examId/:mockTestId/:mockTestTypeId" element={<Suspense fallback={<div>Loading...</div>}><JeeAdvanceGeneralInstructions /></Suspense>}/>
          <Route path="/JeeAdvanceMocktest/:examId/:mockTestId/:mockTestTypeId" element={<Suspense fallback={<LoadingSpinner />}><JeeAdvNew /></Suspense>}/>
          <Route path="/NEETGenralinstructions/:examId/:mockTestId/:mockTestTypeId" element={<Suspense fallback={<div>Loading...</div>}><NEETGenralinstructions /></Suspense>} />
          <Route path="/NEETMockTest/:examId/:mockTestId/:mockTestTypeId"  element={<Suspense fallback={<LoadingSpinner />}><NEETMockTest /></Suspense>} />
          <Route path="/pgdownload" element={<Suspense fallback={<div>Loading...</div>}><PG_DOWNLOAD /></Suspense>} />
          <Route path="/gatedownload/:id" element={<Suspense fallback={<div>Loading...</div>}><GateDownloadPage /></Suspense>} />
          <Route path="/ScientificCalculator" element={<Suspense fallback={<div>Loading...</div>}><ScientificCalculator /></Suspense>} />
          <Route path="/ISRO_Mocktest1/:selectedSubjectId/:selectedMockTestId" element={<Suspense fallback={<LoadingSpinner />}><IsroMocktest1 /></Suspense>} />
          <Route  path="/MechanicalEngineeringGeneralInstructions/:selectedSubjectId/:selectedMockTestId"  element={<Suspense fallback={<div>Loading...</div>}><MechanicalEngineeringGeneralInstructions /></Suspense>}/>
          <Route  path="/GeneralStudiesGeneralInstructions/:selectedSubjectId/:selectedMockTestId" element={<Suspense fallback={<div>Loading...</div>}><GeneralStudiesGeneralInstructions /> </Suspense>} />
          <Route  path="/CivilEngineeringGeneralInstructions/:selectedSubjectId/:selectedMockTestId" element  ={<Suspense fallback={<div>Loading...</div>}><CivilEngineeringGeneralInstructions /></Suspense>} />
          <Route  path="/ESE_Mocktest1/:selectedSubjectId/:selectedMockTestId"  element={<Suspense fallback={<LoadingSpinner />}><EseMocktest1 /></Suspense>}/>
          <Route path="/GateCEGeneralInstructions/:selectedSubjectId/:selectedMockTestId" element={<Suspense fallback={<div>Loading...</div>}>  <GateCEGeneralInstructions /> </Suspense>}/>
          <Route path="/GateCEGeneralInstructions/:selectedSubjectId/:selectedMockTestId" element={<Suspense fallback={<div>Loading...</div>}><IsroEceGeneralInstructions /></Suspense>} />
          <Route path="/IsroEceGeneralInstructions/:selectedSubjectId/:selectedMockTestId" element={<Suspense fallback={<div>Loading...</div>}><IsroEceGeneralInstructions /></Suspense>} />
          <Route path="/VideoLectures" element={<Suspense fallback={<div>Loading...</div>}><JeeVideoLectures /></Suspense>} />
          <Route path="/JeeMathsTopics/:exam/:subject" element={<Suspense fallback={<div>Loading...</div>}><JeeMathsTopics /></Suspense>} />

          <Route path="/eGRADTutorTestInterface" element={<Suspense fallback={<div>Loading...</div>}><Interviewtestform /></Suspense>} />
          <Route path="/eGRADTutorTestInterface/501" element={<Suspense fallback={<div>Loading...</div>}><Egradtest /></Suspense>} />
        </Routes>
      </Router>
    </State>
  );
};

export default App;
