import React, { useEffect, useState, useCallback, useMemo,lazy, Suspense } from "react";
import {onLCP} from 'web-vitals'
import { Link } from "react-router-dom";
import "./EgateL.css";
import capImgCompressed from './browser-removebg-preview.15bc2498a41e2b07c4e8FomLive.png'
import logoCompressed from './EGTLogoCompressed.jpg';
const Footer2 = lazy(() => import('../components/UG/Footer2'));
const useLCPTracking=()=>{
    useEffect(()=>{
        onLCP((metric)=>{
            //console.log("LCP Value",metric)
        })
    },[])
}
export const Egate = () => {
    const [comingSoonPopup, setComingSoonPopup] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    useLCPTracking();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const togglePopup = useCallback(() => setShowPopup(prev => !prev), []);

    const popupContent = useMemo(() => (
        <div className="popupExamPAgeContainer">
            <div className="popup-innerExamPAge">
                <p>Admissions closed for 2024. Registrations for 2025 open from May 15th, 2024.</p>
                <button onClick={togglePopup}>Close</button>
            </div>
        </div>
    ), [togglePopup]);

    return (
        <div className="landingEgate_page">
            {comingSoonPopup && (
                <div className="landing_page_pop_up">
                    <p>we are updating...</p>
                    <span>coming soon</span>
                    <label onClick={() => setComingSoonPopup(false)} className="close_popup">X</label>
                </div>
            )}

            <div className="Egate_logo_with_Link">
                <div className="Egate_landing_header-e6578i">
                    <div className="Egate_logo_hjurnfh">
                        {/* <img src="/EGTLogo.jpg" alt="Egate Logo" width="250" /> */}
                        <img src={logoCompressed} loading='lazy'
                         alt="Egate Logo" width="350" />
                    </div>

                    <div className="Egate_OurOfferings_btn_container">


<Link to="/OurOfferingsPage" className="OurOfferings_btn" onClick={togglePopup}>
  Development Center
</Link>

                    </div>
                </div>
            </div>
            <section className="EGATE_eLanding_Container">
                <div className="Egate_landing">
                    <div className="EGATE_landingE">
                        <div className="Egate_loge_landing">
                            <img src={capImgCompressed} alt="Egate" className="capImgInLandingPage" 
                            width="250" height="200" 
                            />
                        </div>
                        <div className="Egate_landiing_ojnkl">
                            <h2>Welcome to eGRADTutor</h2>
                            <p>...tutoring by grads from IIT's/IISc</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="Egate_eLanding_Container Egate_landing">
                <div className="EGATE_eCordsLanding">
                    {["UG", "PG"].map(type => (
                        <div className="Ug_egatePAge-pg" key={type}>
                            <ul className="EGATE_elandUl">
                                <Link className="EGATE_elanLink" to={`/${type}Home`} onClick={() => window.scrollTo(0, 0)}>
                                    <h3 className="Pg_buttonn">
                                        <span className="text">{type} ENTRANCE EXAMS</span>
                                    </h3>
                                </Link>
                                {type === "UG" ? (
                                    <>
                                        <li><Link to="/UgExamHomePage/01">JEE (Main)</Link></li>
                                        <li><Link to="/UgExamHomePage/09">JEE (Advanced)</Link></li>
                                        <li><Link to="/UgExamHomePage/02">NEET</Link></li>
                                        <li><Link to="/UgExamHomePage/03">BITSAT</Link></li>
                                    </>
                                ) : (
                                    <>
                                        <li><Link to="/UgExamHomePage/11">GATE</Link></li>
                                        <li><Link to="/UgExamHomePage/12">IIT-JAM</Link></li>
                                        <li><Link to="/UgExamHomePage/13">ESE Prelims</Link></li>
                                        <li><Link to="/UgExamHomePage/14">JEST</Link></li>
                                    </>
                                )}
                            </ul>
                        </div>
                    ))}
                    {showPopup && popupContent}
                </div>
            </section>
            <Suspense fallback={<div>Loading...</div>}>
        <Footer2 />
      </Suspense>
        </div>
    );
};